.about_cards{
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 32px;
}


.about_item{
    padding: 16px;
    max-width: 866px;
    margin: auto auto;
    padding: 32px;
}

.sobre_description{
    text-align: justify;
    width: 100%;
    margin: auto auto;
}

.about_divider{
    max-width: 1000px;
    margin: auto auto;
}

@media (max-width:678px) {
    .sobre_description{
        text-align: center;
    }
}