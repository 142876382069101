@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;1,100&display=swap');
 
 
:root {
    --background: #1E2437;
    --background-container: #1E2437;
    --primary-color: #1E2437;
    --secondary-color: #B5967C;
    
    --background-white: #ffffff;
    --text-color-black: #1E2437;
    --text-color-white: #ffffff;
    --tittle-primary: #B5967C;
    
    
    --font: 'Poppins', sans-serif;
}

::selection {
  background-color: var(--secondary-color);
  color: var(--text-color-white);
}

@keyframes card {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  opacity: 0;
  transition: all 1s;
  transform: translateY(20px);
}

.show {
  opacity: 1;
  transition: all 1s;
}