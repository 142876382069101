.service__item{
    width: 200px;
    border: 1px solid rgb(1, 1,1, 0.1);
    border-radius: 10px;
    height: 360px;
    box-shadow: 1px 4px 1px 0 rgb(1, 1,1, 0.1);
}

.service__item_icon{
    width: 50px;
    height: 50px;
    margin: auto auto;
    display: flex;
    margin-top: 24px;
}

.service__item_title{
    font-weight: bold;
    text-align: center;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 35px;
}

.service_item__divider{
    padding: 0 8px;
}

.service__description{
    text-align: left;
    width: 160px;
    margin: auto auto;
    font-size: 15px;
    height: 100%;   
}

@media (max-width: 768px) {
    .service__item{
        height: 280px;
    }
    .service__description{
        font-size: 14px;
    }
    
}