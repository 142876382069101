@import '../../styles/variables.css';

#home {
  background-color: #26293A;
  color: var(--text-color);
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.boxVideo {
  position: relative;
  padding-bottom: 56.25%; 
  padding-top: 25px;
  height: 0;
  margin:0 auto;
  
}
 

.back_slide_item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@media  (max-width: 1690px) {
  #home{
    height: 100vh;
  }
  .back_slide_item {
    position: absolute; 
    left: 0;
    width: 100%;
    height: 100vh;
  }
  
}
