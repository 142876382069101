#solucoes{
    position: relative;
     margin-bottom: 32px;
}

.services_container{
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 666px;
    margin:auto auto;
    gap: 16px;
    flex-wrap: wrap;
}
 

@media screen {
    
}